@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@400;500;600;700&display=swap");
body {
  overflow-x: hidden;
}
.dynamic-body {
  min-height: var(--body-min-height);
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 12px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #420789;
  /* border-radius: 10px; */
}

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
} */

.title {
  font-family: var(--font-family);
  text-transform: uppercase;
  font-weight: 600;
  font-size: 25px;
  line-height: 35px;
  text-align: center;
  color: black;
}
.title-white {
  color: white;
  margin: 10px;
}
.sub-title {
  font-family: var(--font-family);
  text-transform: uppercase;
  font-weight: 600;
  font-size: 15px;
  line-height: 35px;
  text-align: center;
}

.text {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
}
.text-white {
  color: white;
}

.mobile-view {
  margin: auto;
  max-width: 770px;
}
:root {
  --font-family: "Inter";
  --font-style-normal: "normal";
  --font-weight-500: 500;
  --font-weight-400: 400;
  --font-weight-600: 600;
  --font-weight-700: 700;
  --font-size-10: 0.731vw;
  --font-size-12: 0.878vw;
  --font-size-13: 0.9vw;
  --font-size-14: 1.025vw;
  --font-size-15: 1.098vw;
  --font-size-16: 1.171vw;
  --font-size-18: 1.318vw;
  --font-size-20: 1.464vw;
  --font-size-22: 1.611vw;
  --font-size-24: 1.757vw;
  --line-height-17: 1.245vw;
  --color-grey-input-text: rgba(18, 18, 18, 0.5);
  --color-grey-input-text-9: rgba(18, 18, 18, 0.9);
  --color-black-text: #121212;
  --tab-color: rgba(18, 18, 18, 0.8);
  --tab-active-color: #ffffff;
  --tab-bg-color: rgba(75, 26, 133, 0.1);
  --tab-active-bg-color: #1c023b;
}
